import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Main from '../components/Main'
import ImageSection from '../components/ImageSection'
import { s, sButtonBack } from '../style'
import Features from '../components/Features'
import Button from '../components/Button'
import Collapses from '../components/Collapses'
import FormSectionQuote from '../sections/forms/FormSectionQuote'
import BannerContact from '../sections/banners/BannerContact'
import Events from '../components/Events'
import Video from '../components/Video'

const serviceType = {
  sea: {
    services: {
      pl: 'Usługi transportu morskiego',
      en: 'Sea transport services',
      ua: 'МОРСЬКІ ТРАНСПОРТНІ ПОСЛУГИ',
    },
    link: '/transport/morski/',
    more: {
      pl: 'Więcej o transporcie morskim',
      en: 'More about sea transport',
      ua: 'БІЛЬШЕ ПРО МОРСЬКИЙ ТРАНСПОРТ',
    },
  },
  air: {
    services: {
      pl: 'Usługi transportu lotniczego',
      en: 'Air transport services',
      ua: 'АВІА ТРАНСПОРТНІ ПОСЛУГИ',
    },
    link: '/transport/lotniczy/',
    more: {
      pl: 'Więcej o transporcie lotniczym',
      en: 'More about air transport',
      ua: 'БІЛЬШЕ ПРО ПОВІТРЯНИЙ ТРАНСПОРТ',
    },
  },
  land: {
    services: {
      pl: 'Usługi transportu lądowego',
      en: 'Land transport services',
      ua: 'ПОСЛУГИ НАЗЕМНОГО ТРАНСПОРТУ',
    },
    link: '/transport/ladowy/',
    more: {
      pl: 'Więcej o transporcie lądowym',
      en: 'More about land transport',
      ua: 'БІЛЬШЕ ПРО НАЗЕМНИЙ ТРАНСПОРТ',
    },
  },
  service: {
    services: {
      pl: 'Usługi spedycyjne',
      en: 'Forwarding services',
      ua: 'ЕКСПЕДИТОРСЬКІ ПОСЛУГИ',
    },
    link: '/uslugi/',
    more: {
      pl: 'Więcej o usługach',
      en: 'More about services',
      ua: 'Детальніше про послуги',
    },
  },
}

const content = {
  features: {
    pl: 'Dlaczego warto?',
    en: 'Why is it worth it?',
    ua: 'Чому варто?',
  },
}

const stagesContent = {
  'import-azja': {
    title: {
      pl: 'Poznaj etapy łańcucha dostaw',
      en: 'Learn about the supply chain phases',
      ua: 'Дослідіть етапи ланцюга поставок',
    },
    desc: {
      pl: 'Pełna obsługa procesów transportowych dla E-commerce Chiny-Europa',
      en: 'Complete freight processes support for China-Europe e‑commerce',
      ua: 'Повне обслуговування транспортних процесів для E-commerce Китай-Європа',
    },
  },
}

const prefix = (type) => {
  switch (type) {
    case 'sea':
      return 'transport/morski/'
    case 'air':
      return 'transport/lotniczy/'
    case 'land':
      return 'transport/ladowy/'
    case 'service':
      return 'uslugi/'
    default:
      return ''
  }
}

export default function ServiceTemplate({ data }) {
  const { lang } = useLangContext()
  const { setFormOpen, setFormType } = useFormContext()

  const service = data.services.nodes.find((node) => node.lang.slug === 'pl')
  
  const {
    slug,
    title,
    h1,
    excerpt,
    seo_title,
    seo_desc,
    image,
    body,
    isbodylarge,
    type,
    sections,
    videos,
    features,
    faq,
    presentation,
    stages,
  } = service

  const additionalText = data.additional ? data.additional.text.childMarkdownRemark.html : null

  const button = {
    text: {
      pl: 'Dowiedz się więcej',
      en: 'Learn more',
      ua: 'ДІЗНАТИСЯ БІЛЬШЕ ',
    },
    action: 'SCROLL',
  }

  const buttonSecond = {
    text: {
      pl: 'Wycena transportu',
      en: 'Transport Quote',
      ua: 'ЦІНА ТРАНСПОРТУВАННЯ',
    },
    action: () => {
      setFormType('QUOTE')
      setFormOpen(true)
    },
  }

  const buttonPresentation = {
    text: { pl: 'Zobacz prezentację', en: 'Open presentation', ua: '', },
    link: presentation,
    targetBlank: true,
  }

  useEffect(() => {
    setFormType('QUOTE')
  }, [setFormType])

  return (
    <Layout>
      <Seo
        title={seo_title}
        desc={seo_desc}
        imageUrl={image.file.url}
        url={prefix(type.name) + slug}
      />
      {/* {lang === 'pl' || 'en'} */}
      {lang !== 'ua' && <Intro
        title={title}
        desc={excerpt ? excerpt : seo_desc}
        button={button}
        buttonSecond={presentation ? buttonPresentation : buttonSecond}
        image={image}
        position='center'
      />}
      {lang === 'ua' && <Intro
        title={title}
        desc={excerpt ? excerpt : seo_desc}
        button={button}
        buttonSecond={buttonSecond}
        image={image}
        position='center'
      />}
      <Main
        h={1}
        title={h1}
        subtitle={serviceType[type.name].services[lang]}
        body={!isbodylarge && body?.childMarkdownRemark.html}
        article={isbodylarge && body?.childMarkdownRemark.html}
      >
        <div css={{ [s.md]: { paddingTop: 80 } }} />
        {sections?.map(({ title, desc, image, html }, id) => (
          <ImageSection
            h={2}
            s={3}
            key={id}
            title={title}
            desc={desc.childMarkdownRemark.html}
            image={image}
            html={html}
          />
        ))}
      </Main>
      {stages && stagesContent[slug] && (
        <Main
          h={2}
          s={2}
          title={stagesContent[slug].title[lang]}
          body={stagesContent[slug].desc[lang]}
        >
          <Events
            grid3lg
            data={stages.map(({ title, desc, image }) => ({
              title,
              image,
              desc: desc.childMarkdownRemark.html,
            }))}
          />
        </Main>
      )}
      {videos && (
        <Main>
          <Video video={videos.first} videos={videos.others} />
        </Main>
      )}
      {features && (
        <Main h={2} title={content.features[lang]}>
          <Features
            data={features.features}
            gridRepeat={features.features?.length >= 12 && 3}
          />
        </Main>
      )}
      {faq && (
        <Main
          h={2}
          s={1}
          title={faq.title}
          desc={faq.desc}
          subtitle='FAQ'
          extraCssTitle={{
            [s.lg]: { maxWidth: '50%' },
          }}
        >
          <Collapses data={faq.questions} fullWidth noGaps light />
          <div
            css={{ paddingTop: '6rem', [s.sm_down]: { paddingTop: '2rem' } }}
          />
          <Button link={serviceType[type.name].link}>
            {serviceType[type.name].more[lang]}
          </Button>
        </Main>
      )}
      <BannerContact />

      <FormSectionQuote />
      <div css={sButtonBack}>
        <Button link={serviceType[type.name].link}>
          {serviceType[type.name].more[lang]}
        </Button>
      </div>
      {additionalText && (
        <Main
          article={additionalText}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    services: allContentfulService(filter: { slug: { eq: $slug } }) {
      nodes {
        lang {
          slug
        }
        type {
          name
        }
        slug
        title
        seo_title
        seo_desc
        presentation
        h1
        excerpt
        body {
          childMarkdownRemark {
            html
          }
        }
        isbodylarge
        image {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
          file {
            url
          }
        }
        sections {
          title
          desc {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
              aspectRatio: 1.77
            )
          }
          html
        }
        videos {
          first
          others
        }
        stages {
          title
          desc {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [PNG]
              aspectRatio: 1.77
            )
          }
          html
        }
        features {
          features {
            desc
            title
          }
        }
        faq {
          questions {
            a
            q
          }
          desc
          title
        }
      }
    }
    additional: contentfulDodatkowyTekst(slug: {eq: $slug}) {
      text {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
